import React, { createContext, useState } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = (item) => {
    setCartItems((prevItems) => [...prevItems, item]);
  };

  const addRefundToCart = (item) => {
    const found = cartItems.find(
      (x) => x.ID === item.ID && x.type === item.type
    );
    if (found) return;
    setCartItems((prevItems) => [...prevItems, item]);
  };

  const removeFromCart = (itemKey) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.key !== itemKey)
    );
  };

  const updateItemQuantity = (itemKey, quantity) => {
    const item = cartItems.find((x) => x.key === itemKey);
    if (item?.isRefund && item?.refundableQuantity < quantity) return;
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.key === itemKey ? { ...item, quantity } : item
      )
    );
  };

  const updateItem = (key, updates) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.key === key ? { ...item, ...updates } : item
      )
    );
  };

  const updateNote = (itemKey, note) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => (item.key === itemKey ? { ...item, note } : item))
    );
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const calculateTotalPrice = () => {
    return cartItems.reduce(
      (total, item) => total + (item.unitPrice - item.discount) * item.quantity,
      0
    );
  };

  const calculateTotalTax = () => {
    return cartItems.reduce(
      (totalTax, item) =>
        totalTax +
        (item.unitPrice - item.discount) *
          item.quantity *
          (item.taxValue / 100),
      0
    );
  };

  const calculateTotalDiscount = () => {
    return cartItems.reduce(
      (totalDiscount, item) => totalDiscount + item.discount * item.quantity,
      0
    );
  };

  const calculateTotalItems = () => {
    return cartItems.reduce(
      (totalItems, item) => totalItems + item.quantity,
      0
    );
  };

  const cartContextValue = {
    cartItems,
    addToCart,
    addRefundToCart,
    removeFromCart,
    updateItemQuantity,
    updateItem,
    updateNote,
    clearCart,
    calculateTotalPrice,
    calculateTotalTax,
    calculateTotalDiscount,
    calculateTotalItems,
  };

  return (
    <CartContext.Provider value={cartContextValue}>
      {children}
    </CartContext.Provider>
  );
};
